.banners-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 32px;

  img {
    width: 100%;
    border-radius: 20px;
  }
}